<template>
  <div class="auth">
    <vue-particles
      color="#FFF"
      :particle-opacity="0.7"
      :particles-number="50"
      shape-type="circle"
      :particle-size="4"
      lines-color="#FFF"
      :lines-width="1"
      :line-linked="true"
      :line-opacity="0.4"
      :lines-distance="200"
      :move-speed="4"
      :hover-effect="true"
      hover-mode="repulse"
      :click-effect="true"
      click-mode="push"
      class="auth__particles"
    />

    <div class="auth__card">
      <div class="d-block text-center align-items-center justify-content-center dilshod">
        <b-img
          class="img__fly bunyodov"
          style="width: 200px"
          fluid
          src="../assets/images/login/ilma.svg"
          alt="#"
        />
        <div class="text-uppercase text-black fs-17px mt-2">
          <span class="font-weight-light text-white">
            ILMA
          </span>
          <br>
          <span
            class="font-weight-bolder"
            style="color: #fff"
          >
            Судебный конвейер
          </span>
        </div>
      </div>

      <validation-observer
        ref="loginValidation"
        class="w-100"
      >
        <b-form
          class="auth-login-form"
          @submit.prevent
        >
          <!-- email -->
          <b-form-group
            label-for="login-email"
          >
            <label class="text-white">Логин</label>
            <validation-provider
              #default="{ errors }"
              name="Логин"
              rules="required"
            >
              <b-form-input
                v-model="username"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <div class="d-flex justify-content-between">
              <label class="text-white">Пароль</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="Пароль"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <button
            class="btn__login text-center"
            type="submit"
            @click="validationForm"
          >
            <span
              v-if="load"
              class="d-flex align-items-center justify-content-center"
            >
              <b-spinner
                small
                variant="white"
              />
              <small class="ml-1">Загрузка...</small>
            </span>
            <span v-else> Войти</span>
          </button>
        </b-form>
      </validation-observer>
    </div>

    <div class="auth__image" />
    <div class="auth__overlay" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BImg,
  BForm,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BImg,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      load: false,
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    document.title = 'ILMA | Логин'
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate()
        .then(success => {
          if (success) {
            this.load = true
            useJwt.login({ username: this.username, password: this.password })
              .then(resp => {
                useJwt.setToken(resp.data.access)
                useJwt.setRefreshToken(resp.data.refresh)
                const { user_data } = resp.data
                useJwt.setUserData(user_data)
                const ability = user_data.permissions.map(e => ({
                  action: 'manage',
                  subject: e,
                }))
                useJwt.setUserAbilities(ability)
                if (user_data?.permissions?.includes('dk_operator')) {
                  this.$router.push('/dk')
                } else if (user_data?.permissions?.includes('dk_manager')) {
                  this.$router.push('/portfolios')
                } else {
                  this.$router.push('/')
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Вход в систему успешно, ${user_data.first_name} ${user_data.last_name}`,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.load = false
              })
              .catch(() => {
                this.load = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Неправильный логин или пароль',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.auth {
  display: flex;
  justify-content: center;
  align-items: center;

  &__particles {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 120;
    width: 100%;
    height: 100vh !important;

    .particles-js-canvas-el {
      height: 99.4% !important;
    }
  }

  &__image {
    background-image: url('~@/assets/images/login/wp2446274.jpg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    background-blend-mode: darken;
    z-index: 80;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    z-index: 100;
  }

  &__card {
    background: rgb(255 255 255 / 15%);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    ///
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 140;
    width: 480px;
    height: auto;
    min-height: 480px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 30px 30px 30px 30px;
  }
}

/* =============== LOGIN_SECTION END ===============*/
.btn__login {
  background: rgb(173, 142, 89);
  color: #fff;
  margin-top: 8px;
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
}

@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.img__fly {
  transform: translatey(0px);
  //-webkit-animation: float 5s ease-in-out infinite;
  //animation: float 5s ease-in-out infinite;
}

@import '@core/scss/vue/pages/page-auth.scss';
</style>
